import React from 'react'
import SocialButtons from '../SocialButtons'

export default function MyContactDetails() {
    return (
        <div>
            <SocialButtons/>
        </div>
    )
}
